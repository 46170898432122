<template>
    <v-card class="ma-2" :loading="loading" color="indigo darken-3" dark>
        <v-card-title>
            <v-icon x-large left>
                mdi-clock-edit-outline
            </v-icon>
            <span class="text-subtitle-1 font-weight-medium">
                Task Aperti: {{listaTask.length}} [Priorità: {{ priorita==100 ? ' - ':priorita }}]
            </span>
            <!--
                <v-spacer></v-spacer>
                <v-chip small dark color="gray"
                        v-for="tag in listaTagsIni"
                        :key="tag"
                        @click="setTagRicerca(tag)"
                        v-text="tag.id">
                </v-chip>
            -->
        </v-card-title>
        <v-card-text>
            <v-data-table :headers="headers"
                          :items="listaTask"
                          :items-per-page="5"
                          class="elevation-0 transparent"
                          disable-items-per-page
                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': '',
                                            'items-per-page-options':[5]
                                          }"
                          no-data-text="Nessun task aperto">
                <template v-slot:header.dataPresaInCarico="{ item }">
                    <span>Data Presa in Carico</span>
                    <br />
                    <span>Data Scadenza</span>
                </template>
                <template v-slot:item.descrizione="{ item }">
                    <span v-text="item.descrizione" />
                    <br/>
                    <v-chip small dark color="gray"
                            v-for="tag in item.pratica.tagList"
                            :key="tag"
                            @click="setTagRicerca(tag)"
                            v-text="tag">
                    </v-chip>
                </template>
                <template v-slot:item.dataPresaInCarico="{ item }">
                    <span class="text-subtitle" v-text="localDateTime(item.dataPresaInCarico)" />
                    <br />
                    <span class="text-subtitle" v-text="localData(item.pratica.dataScadenza)" />
                </template>
                <template v-slot:item.pratica="{ item }">
                    <span v-text="item.pratica.tipoPratica.descrizione" />
                    <br />
                    <span v-if="item.pratica.cliente" class="font-weight-light" v-text="item.pratica.cliente.denominazioneNormalizzata" />
                    <span v-else class="font-weight-light" v-text="item.pratica.descrizione" />
                </template>
                <template v-slot:item.apri="{ item }">
                    <v-btn icon @click="apriPratica(item)" class="ml-2">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card-text>
        <template slot="progress">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </template>
    </v-card>
</template>



<script>
    import { callService, gLocalDateTime, gLocalData } from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {
        props: ['priorita'],
        data: () => ({
            loading: false,
            hCard: 450,
            listaTask: [],
            listaTagsIni: [],
            listaTagsCheck: [],
            pratica:{},
            headers: [
                { text: 'Task', value: 'descrizione' },
                { text: 'Pratica', value: 'pratica' },
                { text: 'Data Presa in Carico', value: 'dataPresaInCarico' },
                { text: '', value: 'apri' },
            ],
        }),
        methods: {            
            localDateTime(v) {
                return gLocalDateTime(v);
            },
            localData(v) {
                return gLocalData(v);
            },           
            apriPratica(item) {
                this.pratica = JSON.parse(JSON.stringify(item.pratica));
                if(this.$store.state.conf.isService) {
                    bus.$emit('dialog-show-servizio', this.pratica);
                }
                else {
                    bus.$emit('dialog-show-pratica', this.pratica);
                }
            },
            async onPraticaChanged(){
                await this.internalCreated();
            },
            setTagRicerca() {

            },
            async internalCreated() {
                this.loading = true;
                let param = [this.$store.state.utente.operatore.id,this.priorita];
                this.listaTask = await callService("api/dashboard/listaTask", param);
                param = [this.$store.state.utente.operatore.id];
                this.listaTagsIni = await callService("api/tags/tagsPraticheOperatore", param);
                this.loading = false;
            }
        },
        computed: {
            
        },       
        watch: {
            priorita: async function(val){
                await this.internalCreated();
            }
        }, 
        async created() {
            bus.$on('pratica-changed', this.onPraticaChanged);            
            await this.internalCreated();
        },        
        beforeDestroy() {
            bus.$off('pratica-changed', this.onPraticaChanged);           
        }
    }</script>

