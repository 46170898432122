<template>
    <v-card class="ma-2" :height="hCard" :loading="loading" color="cyan darken-3" dark>
        <v-card-title>
            <v-icon x-large left>
                mdi-chart-timeline-variant
            </v-icon>
            <span class="text-subtitle-1 font-weight-medium">
                Distribuzione per Tipologia - {{nomeProf}}: {{totale}} Clienti
            </span>
        </v-card-title>
        <v-card-text>
            <GChart v-if="datiClienti && datiClienti.length>1" type="PieChart" 
                    :data="chartClienti"
                    :options="chartOptionsClienti"
                    :settings="{language: 'it'}" />
        </v-card-text>
        <template slot="progress">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </template>
    </v-card>
</template>

<script>
    import { callService } from '@/modules/utilities.js'

    import { GChart } from 'vue-google-charts/legacy'

    export default {
        props: ['idProf'],
        components: { GChart },
        data: () => ({
            loading: false,
            hCard: 450,
            datiClienti: null,
            chartOptionsClienti: {
                height: 400,
                chartArea: {width: '90%', height: '90%'},
                pieSliceText: 'value-and-percentage',
                legend: { position: 'left' },
                backgroundColor: { fill:'transparent' },
                colors: ["blue", "coral","goldenrod","darkred", "green","brown","darkorange"]
                //vAxis: { format: '€ #' }
            },
            nomeProf:''
        }),
        methods: {            
            async load() {
                this.loading = true;
                let listaTipoCliente = this.$store.state.listaTipoCliente;
                let clientiEstesi = this.$store.state.clientiEstesi.filter(ce=>!ce.cliente.isCancellato);
                if(this.idProf) {
                    clientiEstesi=clientiEstesi.filter(ce=>ce.cliente.professionista.id==this.idProf);
                    let operProf=this.$store.state.listaOperatoriProfessionisti.find(o=>o.professionista.id==this.idProf)
                    this.nomeProf = operProf.denominazioneNormalizzata;
                }
                else {
                    this.nomeProf="Totale";
                }
                this.totale = clientiEstesi.length;
                let datiClienti=[["Tipo Cliente", "Numero"]];

                listaTipoCliente.forEach(function(tipo) {
                    let count = clientiEstesi.filter(ce => ce.cliente.tipoCliente.id==tipo.id).length;
                    datiClienti.push([tipo.descrizione,count]);
                });   

                this.datiClienti=datiClienti;
                this.loading = false;
            }
        },
        computed: {
            chartClienti() {          
                if (this.datiClienti == null) return null;
                return this.datiClienti;
            }
        },        
        watch: {
            idProf: async function(val){
                await this.load();
            }
        },
        async created() {
            await this.load();
        }
    }</script>

